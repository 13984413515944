<template>
  <div>
    
    <div class="row my-4 justify-content-center">
      <div class="col-10 text-center">
        <div class="btn btn-primary" v-on:click="btnKeys">Keys</div>
        <div class="btn btn-primary" v-on:click="btnAnswers">Answers</div>
        <div class="btn btn-primary" v-on:click="btnQuestions">Questions</div>
        <div class="btn btn-primary" v-on:click="btnYears">Years</div>
        <div class="btn btn-primary" v-on:click="btnImagesQuestions">Imges question</div>
        <div class="btn btn-primary" v-on:click="btnThemes">Themes</div>
        <div class="btn btn-primary" v-on:click="btnCourses">Courses</div>
        <div class="btn btn-primary" v-on:click="btnBlocks">Blocks</div>
      </div>
    </div>

    <!-- btn import -->
    <div class="row justify-content-center">
      <div class="col-10">

        <div class="input-group mb-3" v-show="importKeys.status">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroupFileAddon01">Keys</span>
          </div>
          <div class="custom-file">
            <input type="file" class="custom-file-input" 
              ref="keys" id="inputGroupKeys" 
              v-on:change="handleUpdateKeys()" 
              aria-describedby="inputGroupFileAddon01">
            <label class="custom-file-label" for="inputGroupKeys">Choose file</label>
          </div>
          <div>
            <div class="btn btn-primary" v-on:click="submitFileKeys()">
              Subir
            </div>
          </div>
        </div>

        <div class="input-group mb-3" v-show="importAnswers.status">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroupFileAddon01">Answers</span>
          </div>
          <div class="custom-file">
            <input type="file" class="custom-file-input" 
              ref="answers" id="inputGroupAnswers" 
              v-on:change="handleUpdateAnswers()" 
              aria-describedby="inputGroupFileAddon01">
            <label class="custom-file-label" for="inputGroupAnswers">Choose file</label>
          </div>
          <div>
            <div class="btn btn-primary" v-on:click="submitFileAnswers()">
              Subir
            </div>
          </div>
        </div>

        <div class="input-group mb-3" v-show="importQuestions.status">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroupFileAddon01">Questions</span>
          </div>
          <div class="custom-file">
            <input type="file" class="custom-file-input" 
              ref="questions" id="inputGroupQuestions" 
              v-on:change="handleUpdateQuestions()" 
              aria-describedby="inputGroupFileAddon01">
            <label class="custom-file-label" for="inputGroupQuestions">Choose file</label>
          </div>
          <div>
            <div class="btn btn-primary" v-on:click="submitFileQuestions()">
              Subir
            </div>
          </div>
        </div>

        <div class="input-group mb-3" v-show="importImagesQuestions.status">
            <UpdateImage/>
        </div>

        <div class="input-group mb-3" v-show="importThemes.status">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroupFileAddonThemes">Temas</span>
          </div>
          <div class="custom-file">
            <input type="file" class="custom-file-input" 
              ref="themes" id="inputGroupFile01" 
              v-on:change="handleUpdateThemes()" 
              aria-describedby="inputGroupFileAddonThemes">
            <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
          </div>
          <div>
            <div class="btn btn-primary" v-on:click="submitFileThemes()">
              Subir
            </div>
          </div>
        </div>
        
      </div>
    </div>
    
    <!-- get data questions -->
    <div v-if="importQuestions.status">

      <div v-if="!dataQuestions.viewSearch">
        <p>sin search</p>
        <div class="row justify-content-center">
          <div class="col-10">
            <div class="form-group">
              <input
                v-model="dataQuestions.search.body"
                class="form-control form-control-lg"
                type="text"
                placeholder="Search questions"/>
            </div>
            <div class="form-row">
              <div class="col-2">
                <input
                v-model="dataQuestions.search.id"
                class="form-control form-control-lg"
                type="text"
                placeholder="id"/>
              </div>
              <div class="col-2">
                <input
                v-model="dataQuestions.search.user"
                class="form-control form-control-lg"
                type="text"
                placeholder="user"/>
              </div>
              <div class="col-2">
                <input
                v-model="dataQuestions.search.block"
                class="form-control form-control-lg"
                type="text"
                placeholder="block"/>
              </div>
              <div class="col-2">
                <input
                v-model="dataQuestions.search.course"
                class="form-control form-control-lg"
                type="text"
                placeholder="course"/>
              </div>
              <div class="col-2">
                <input
                v-model="dataQuestions.search.theme"
                class="form-control form-control-lg"
                type="text"
                placeholder="theme"/>
              </div>
              <div class="col-2">
                <input
                v-model="dataQuestions.search.difficulty"
                class="form-control form-control-lg"
                type="text"
                placeholder="difficulty"/>
              </div>
              <div class="col-2">
                <input
                v-model="dataQuestions.search.point"
                class="form-control form-control-lg"
                type="text"
                placeholder="point"/>
              </div>
              <div class="col-2">
                <input
                v-model="dataQuestions.search.order"
                class="form-control form-control-lg"
                type="text"
                placeholder="order"/>
              </div>
            </div>
            <div class="form-group">
              <form ref="formSearch">
                <div class="form-group">
                    <select class="form-control" v-model="dataQuestions.amount">
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                    </select>
                </div>
              </form>
            </div>
            <div class="form-group text-center">
              <button type="button" class="btn btn-primary" v-on:click="search">Buscar</button>
            </div>
            
            <div class="form-group">
              <p>Total de preguntas: {{ this.dataQuestions.listData.total }}</p>              
            </div>
            <div class="form-group">
              <button type="button" 
                class="btn btn-primary" 
                data-toggle="modal" 
                data-target="#createQuestion">
                Crear
              </button> 
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <table class="table table-hover" v-if="!dataQuestions.loadingData">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Block</th>
                  <th scope="col">Course</th>
                  <th scope="col">Theme</th>
                  <th scope="col">Body</th>
                  <th scope="col">Difficulty</th>
                  <th scope="col">Point</th>
                  <th scope="col">Update</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="quiz in dataQuestions.listData.data" :key="quiz.id">
                  <th scope="row"> {{ quiz.id }}</th>
                  <td>{{ quiz.user.name}}</td>
                  <td>{{ quiz.block.name}}</td>
                  <td>{{ quiz.course.name}}</td>
                  <td>{{ quiz.team.name}}</td>
                  <td>
                    <div v-html="quiz.body"></div>
                  </td>
                  <td>{{ quiz.difficulty.name}}</td>
                  <td>{{ quiz.point.name}}</td>
                  <td>{{ quiz.created_at}}</td>
                  <td> 
                    <button type="button" 
                      class="btn btn-primary" 
                      data-toggle="modal" 
                      data-target="#questionView"
                      v-on:click="viewQuestion(quiz.id)">
                      Ver
                    </button> 
                    <button type="button" 
                      class="btn btn-primary" 
                      data-toggle="modal" 
                      data-target="#questionFull"
                      v-on:click="viewQuestion(quiz.id)">
                      Editar
                    </button> 
                    <button type="button" 
                      class="btn btn-primary" 
                      data-toggle="modal" 
                      data-target="#questionBody"
                      v-on:click="viewQuestion(quiz.id)">
                      Editar Body
                    </button> 
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
      <div class="row justify-content-center" v-else>
        <div class="col">
          <p>search</p>
          <div class="row justify-content-center">
            <div class="col-10">
              <div class="form-group">
              <input
                v-model="dataQuestions.search.body"
                class="form-control form-control-lg"
                type="text"
                placeholder="Search questions"/>
            </div>
            <div class="form-row">
              <div class="col-2">
                <input
                v-model="dataQuestions.search.id"
                class="form-control form-control-lg"
                type="text"
                placeholder="id"/>
              </div>
              <div class="col-2">
                <input
                v-model="dataQuestions.search.user"
                class="form-control form-control-lg"
                type="text"
                placeholder="user"/>
              </div>
              <div class="col-2">
                <input
                v-model="dataQuestions.search.block"
                class="form-control form-control-lg"
                type="text"
                placeholder="block"/>
              </div>
              <div class="col-2">
                <input
                v-model="dataQuestions.search.course"
                class="form-control form-control-lg"
                type="text"
                placeholder="course"/>
              </div>
              <div class="col-2">
                <input
                v-model="dataQuestions.search.theme"
                class="form-control form-control-lg"
                type="text"
                placeholder="theme"/>
              </div>
              <div class="col-2">
                <input
                v-model="dataQuestions.search.difficulty"
                class="form-control form-control-lg"
                type="text"
                placeholder="difficulty"/>
              </div>
              <div class="col-2">
                <input
                v-model="dataQuestions.search.point"
                class="form-control form-control-lg"
                type="text"
                placeholder="point"/>
              </div>
              <div class="col-2">
                <input
                v-model="dataQuestions.search.order"
                class="form-control form-control-lg"
                type="text"
                placeholder="order"/>
              </div>
            </div>
              <div class="form-group text-center">
                <button type="button" class="btn btn-primary" v-on:click="search">Buscar</button>
              </div>
              <div class="form-group">
              <button type="button" 
                class="btn btn-primary" 
                data-toggle="modal" 
                data-target="#createQuestion">
                Crear
              </button> 
            </div>
              <div class="form-group">
                <form ref="formSearch">
                  <div class="form-group">
                      <select class="form-control" v-model="dataQuestions.amount">
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="150">150</option>
                          <option value="200">200</option>
                      </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="form-group">
              <p>Total de preguntas: {{ this.dataQuestions.resSearch.total }}</p>              
          </div>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                  <th scope="col">id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Block</th>
                  <th scope="col">Course</th>
                  <th scope="col">Theme</th>
                  <th scope="col">Body</th>
                  <th scope="col">Difficulty</th>
                  <th scope="col">Point</th>
                  <th scope="col">Update</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(quiz, index) in dataQuestions.resSearch.data" :key="quiz.id">
                <th scope="row"> {{ index +1 }}</th>
                <td>{{ quiz.id}}</td>
                <td>{{ quiz.user.name}}</td>
                <td>{{ quiz.block.name}}</td>
                <td>{{ quiz.course.name}}</td>
                <td>{{ quiz.team.name}}</td>
                <td>
                  <div v-html="quiz.body"></div>
                </td>
                <td>{{ quiz.difficulty.name}}</td>
                <td>{{ quiz.point.name}}</td>
                <td>{{ quiz.created_at}}</td>
                <td> 
                  <button type="button" 
                    class="btn btn-primary" 
                    data-toggle="modal" 
                    data-target="#questionView"
                    v-on:click="viewQuestionSearch(quiz.id)">
                    Ver
                  </button> 
                  <button type="button" 
                      class="btn btn-primary" 
                      data-toggle="modal" 
                      data-target="#questionFull"
                      v-on:click="viewQuestionSearch(quiz.id)">
                      Editar
                    </button> 
                  <button type="button" 
                      class="btn btn-primary" 
                      data-toggle="modal" 
                      data-target="#questionBody"
                      v-on:click="viewQuestionSearch(quiz.id)">
                      Editar body
                    </button> 
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      
    </div>

    <div v-if="importAnswers.status">

      <div v-if="!dataAnswers.viewSearch">
        <p>sin search</p>
        <div class="row justify-content-center">
          <div class="col-10">
            <div class="form-group">
              <input
                v-model="dataAnswers.searchText"
                class="form-control form-control-lg"
                type="text"
                placeholder="Search questions"/>
            </div>
            <div class="form-group">
              <form ref="formSearch">
                <div class="form-group">
                    <select class="form-control" v-model="dataAnswers.amount">
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                    </select>
                </div>
              </form>
            </div>
            <div class="form-group text-center">
              <button type="button" class="btn btn-primary" v-on:click="searchAnswer">Buscar</button>
            </div>
            
            <div class="form-group">
              <p>Total de preguntas: {{ this.dataAnswers.listData.total }}</p>              
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <table class="table table-hover" v-if="!dataAnswers.loadingData">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">A</th>
                  <th scope="col">B</th>
                  <th scope="col">C</th>
                  <th scope="col">D</th>
                  <th scope="col">E</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="quiz in dataAnswers.listData.data" :key="quiz.id">
                  <th scope="row"> {{ quiz.id }}</th>
                  <td>{{ quiz.a }}</td>
                  <td>{{ quiz.b }}</td>
                  <td>{{ quiz.c }}</td>
                  <td>{{ quiz.d }}</td>
                  <td>{{ quiz.a }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
      <div class="row justify-content-center" v-else>
        <div class="col">
          <p>search</p>
          <div class="row justify-content-center">
            <div class="col-10">
              <div class="form-group">
                <input
                  v-model="dataAnswers.searchText"
                  class="form-control form-control-lg"
                  type="text"
                  placeholder="Search questions"/>
              </div>
              <div class="form-group text-center">
                <button type="button" class="btn btn-primary" v-on:click="searchAnswer">Buscar</button>
              </div>
              <div class="form-group text-center">
                <button type="button" class="btn btn-primary" v-on:click="updateAnswersSinClave">Sin Clave espacio</button>
              </div>
              <div class="form-group">
                <form ref="formSearch">
                  <div class="form-group">
                      <select class="form-control" v-model="dataAnswers.amount">
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="150">150</option>
                          <option value="200">200</option>
                      </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="form-group">
              <p>Total de preguntas: {{ this.dataAnswers.resSearch.total }}</p>              
          </div>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">A</th>
                <th scope="col">B</th>
                <th scope="col">C</th>
                <th scope="col">D</th>
                <th scope="col">E</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(quiz, index) in dataAnswers.resSearch.data" :key="quiz.id">
                <th scope="row"> {{ index +1 }}</th>
                <td>{{ quiz.id}}</td>
                <td>{{ quiz.a }}</td>
                <td>{{ quiz.b }}</td>
                <td>{{ quiz.c }}</td>
                <td>{{ quiz.d }}</td>
                <td>{{ quiz.e }}</td>
                <td>
                    <button type="button" 
                      class="btn btn-primary" 
                      data-toggle="modal" 
                      data-target="#editAnswer"
                      v-on:click="editAnswer(quiz.id)">
                      Editar
                    </button> 
                  </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      
    </div>
    
    <div v-if="importThemes.status">

      <div v-if="!dataThemas.viewSearch">
        <p>sin search</p>
        <div class="row justify-content-center">
          <div class="col-10">
            <div class="form-group">
              <input
                v-model="dataThemas.searchText"
                class="form-control form-control-lg"
                type="text"
                placeholder="Search questions"/>
            </div>
            <div class="form-group">
              <form ref="formSearch">
                <div class="form-group">
                    <select class="form-control" v-model="dataThemas.amount">
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                    </select>
                </div>
              </form>
            </div>
            <div class="form-group text-center">
              <button type="button" class="btn btn-primary" v-on:click="searchTheme">Buscar</button>
            </div>
            
            <div class="form-group">
              <p>Total de preguntas: {{ this.dataThemas.listData.total }}</p>              
            </div>
            <div class="form-group">
              <button type="button" 
                class="btn btn-primary" 
                data-toggle="modal" 
                data-target="#createTheme">
                Crear
              </button> 
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <table class="table table-hover" v-if="!dataThemas.loadingData">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Slug</th>
                  <th scope="col">Course</th>
                  <th scope="col">Course_id</th>
                  <th scope="col">Themes</th>
                  <th scope="col">Status</th>
                  <th scope="col">Update</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(quiz, index) in dataThemas.listData.data" :key="quiz.id">
                  <th scope="row"> {{ index+1 }}</th>
                  <td>{{ quiz.id }}</td>
                  <td>{{ quiz.name }}</td>
                  <td>{{ quiz.slug }}</td>
                  <td>{{ quiz.course.name }}</td>
                  <td>{{ quiz.course_id }}</td>
                  <td>{{ quiz.course.teams_count }}</td>
                  <td>{{ quiz.status.name }}</td>
                  <td>{{ quiz.updated_at }}</td>
                  <td> 
                    <button type="button" 
                      class="btn btn-primary" 
                      data-toggle="modal" 
                      data-target="#editThema"
                      v-on:click="editThema(quiz.id)">
                      Editar
                    </button>  
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
      <div class="row justify-content-center" v-else>
        <div class="col">
          <p>search</p>
          <div class="row justify-content-center">
            <div class="col-10">
              <div class="form-group">
                <input
                  v-model="dataThemas.searchText"
                  class="form-control form-control-lg"
                  type="text"
                  placeholder="Search questions"/>
              </div>
              <div class="form-group text-center">
                <button type="button" class="btn btn-primary" v-on:click="searchTheme">Buscar</button>
              </div>
              <div class="form-group">
                <form ref="formSearch">
                  <div class="form-group">
                      <select class="form-control" v-model="dataThemas.amount">
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="150">150</option>
                          <option value="200">200</option>
                      </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="form-group">
              <p>Total de preguntas: {{ this.dataThemas.resSearch.total }}</p>              
          </div>
          <div class="form-group">
              <button type="button" 
                class="btn btn-primary" 
                data-toggle="modal" 
                data-target="#createTheme">
                Crear
              </button> 
            </div>
          <table class="table table-hover">
            <thead>
              <tr>
                  <th scope="col">#</th>
                  <th scope="col">id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Slug</th>
                  <th scope="col">Course</th>
                  <th scope="col">Course_id</th>
                  <th scope="col">Themes</th>
                  <th scope="col">Status</th>
                  <th scope="col">Update</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(quiz, index) in dataThemas.resSearch.data" :key="quiz.id">
                <th scope="row"> {{ index+1 }}</th>
                  <td>{{ quiz.id }}</td>
                  <td>{{ quiz.name }}</td>
                  <td>{{ quiz.slug }}</td>
                  <td>{{ quiz.course.name }}</td>
                  <td>{{ quiz.course_id }}</td>
                  <td>{{ quiz.course.teams_count }}</td>
                  <td>{{ quiz.status.name }}</td>
                  <td>{{ quiz.updated_at }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      
    </div>
    
    <div v-if="createCourse.status">

      <div v-if="!dataCourses.viewSearch">
        <p>sin search</p>
        <div class="row justify-content-center">
          <div class="col-10">
            <div class="form-group">
              <input
                v-model="dataCourses.searchText"
                class="form-control form-control-lg"
                type="text"
                placeholder="Search questions"/>
            </div>
            <div class="form-group">
              <form ref="formSearch">
                <div class="form-group">
                    <select class="form-control" v-model="dataCourses.amount">
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                    </select>
                </div>
              </form>
            </div>
            <div class="form-group text-center">
              <button type="button" class="btn btn-primary" v-on:click="search">Buscar</button>
            </div>
            
            <div class="form-group">
              <p>Total de preguntas: {{ this.dataCourses.listData.length }}</p>              
            </div>
            <div class="form-group">
              <button type="button" 
                class="btn btn-primary" 
                data-toggle="modal" 
                data-target="#createCourse">
                Crear
              </button> 
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <table class="table table-hover" v-if="!dataCourses.loadingData">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Slug</th>
                  <th scope="col">Avatar</th>
                  <th scope="col">Themes</th>
                  <th scope="col">Status</th>
                  <th scope="col">Date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(quiz, index) in dataCourses.listData" :key="quiz.id">
                  <th scope="row"> {{ index+1 }}</th>
                  <td>{{ quiz.id }}</td>
                  <td>{{ quiz.name }}</td>
                  <td>{{ quiz.slug }}</td>
                  <td>
                    <div class="imgav">
                      <div class="cAvatar">
                        <img :src="appDomainImg + 'courses/' + quiz.avatar" class="cImage"/>
                      </div>
                    </div>
                  </td>
                  <td>{{ quiz.teams_count }}</td>
                  <td>{{ quiz.status.name }}</td>
                  <td>{{ quiz.updated_at }}</td>
                  <td>
                    <button type="button" 
                      class="btn btn-primary" 
                      data-toggle="modal" 
                      data-target="#editCourse"
                      v-on:click="editCourse(quiz.id)">
                      Editar
                    </button> 
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
      <div class="row justify-content-center" v-else>
        <div class="col">
          <p>search</p>
          <div class="row justify-content-center">
            <div class="col-10">
              <div class="form-group">
                <input
                  v-model="dataCourses.searchText"
                  class="form-control form-control-lg"
                  type="text"
                  placeholder="Search questions"/>
              </div>
              <div class="form-group text-center">
                <button type="button" class="btn btn-primary" v-on:click="search">Buscar</button>
              </div>
              <div class="form-group">
                <form ref="formSearch">
                  <div class="form-group">
                      <select class="form-control" v-model="dataCourses.amount">
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="150">150</option>
                          <option value="200">200</option>
                      </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="form-group">
              <p>Total de preguntas: {{ this.dataCourses.resSearch.length }}</p>              
          </div>
          <div class="form-group">
              <button type="button" 
                class="btn btn-primary" 
                data-toggle="modal" 
                data-target="#createCourse"
                v-on:click="submitCourse()">
                Crear
              </button>            
          </div>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                  <th scope="col">Id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Slug</th>
                  <th scope="col">Avatar</th>
                  <th scope="col">Themes</th>
                  <th scope="col">Status</th>
                  <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(quiz, index) in dataCourses.resSearch" :key="quiz.id">
                <th scope="row"> {{ index +1 }}</th>
                <td>{{ quiz.id }}</td>
                  <td>{{ quiz.name }}</td>
                  <td>{{ quiz.slug }}</td>
                  <td>
                    <div class="imgav">
                      <div class="cAvatar">
                        <img :src="appDomainImg + 'courses/' + quiz.avatar" class="cImage"/>
                      </div>
                    </div>
                  </td>
                  <td>{{ quiz.teams_count }}</td>
                  <td>{{ quiz.status.name }}</td>
                  <td>{{ quiz.updated_at }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      
    </div>
    
   <div v-if="importKeys.status">

      <div v-if="!dataKeys.viewSearch">
        <p>sin search</p>
        <div class="row justify-content-center">
          <div class="col-10">
            <div class="form-group">
              <input
                v-model="dataKeys.searchText"
                class="form-control form-control-lg"
                type="text"
                placeholder="Search questions"/>
            </div>
            <div class="form-group">
              <form ref="formSearch">
                <div class="form-group">
                    <select class="form-control" v-model="dataKeys.amount">
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                    </select>
                </div>
              </form>
            </div>
            <div class="form-group text-center">
              <button type="button" class="btn btn-primary" v-on:click="searchKey">Buscar</button>
            </div>
            
            <div class="form-group">
              <p>Total de preguntas: {{ this.dataKeys.listData.total }}</p>              
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <table class="table table-hover" v-if="!dataKeys.loadingData">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Id</th>
                  <th scope="col">Keys</th>
                  <th scope="col">Created</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(quiz, index) in dataKeys.listData.data" :key="quiz.id">
                  <th scope="row"> {{ index+1 }}</th>
                  <td>{{ quiz.id}}</td>
                  <td>{{ quiz.keys }}</td>
                  <td>{{ quiz.created_at }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
      <div class="row justify-content-center" v-else>
        <div class="col">
          <p>search</p>
          <div class="row justify-content-center">
            <div class="col-10">
              <div class="form-group">
                <input
                  v-model="dataKeys.searchText"
                  class="form-control form-control-lg"
                  type="text"
                  placeholder="Search questions"/>
              </div>
              <div class="form-group text-center">
                <button type="button" class="btn btn-primary" v-on:click="searchKey">Buscar</button>
              </div>
              <div class="form-group">
                <form ref="formSearch">
                  <div class="form-group">
                      <select class="form-control" v-model="dataKeys.amount">
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="150">150</option>
                          <option value="200">200</option>
                      </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="form-group">
              <p>Total de preguntas: {{ this.dataKeys.resSearch.total }}</p>              
          </div>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Id</th>
                <th scope="col">Keys</th>
                <th scope="col">Created</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(quiz, index) in dataKeys.resSearch.data" :key="quiz.id">
                <th scope="row"> {{ index +1 }}</th>
                <td>{{ quiz.id}}</td>
                <td>{{ quiz.keys }}</td>
                <td>{{ quiz.created_at }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      
    </div>
  
    <div v-if="createYear.status">

        <div v-if="!dataYears.viewSearch">
          <p>sin search</p>
          <div class="row justify-content-center">
            <div class="col-10">
              <div class="form-group">
                <input
                  v-model="dataYears.searchText"
                  class="form-control form-control-lg"
                  type="text"
                  placeholder="Search questions"/>
              </div>
              <div class="form-group">
                <form ref="formSearch">
                  <div class="form-group">
                      <select class="form-control" v-model="dataYears.amount">
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="150">150</option>
                          <option value="200">200</option>
                      </select>
                  </div>
                </form>
              </div>
              <div class="form-group text-center">
                <button type="button" class="btn btn-primary" v-on:click="searchYear">Buscar</button>
              </div>
              
              <div class="form-group">
                <p>Total de preguntas: {{ this.dataYears.listData.length }}</p>              
              </div>
              <div class="form-group">
                <button type="button" 
                  class="btn btn-primary" 
                  data-toggle="modal" 
                  data-target="#createYear">
                  Crear
                </button> 
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <table class="table table-hover" v-if="!dataYears.loadingData">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Id</th>
                    <th scope="col">Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(quiz, index) in dataYears.listData.data" :key="quiz.id">
                    <th scope="row"> {{ index+1 }}</th>
                    <td>{{ quiz.id }}</td>
                    <td>{{ quiz.name }}</td>
                    <td>{{ quiz.updated_at }}</td>
                    <td>
                      <button type="button" 
                        class="btn btn-primary" 
                        data-toggle="modal" 
                        data-target="#editCourse"
                        v-on:click="editCourse(quiz.id)">
                        Editar
                      </button> 
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        
        <div class="row justify-content-center" v-else>
          <div class="col">
            <p>search</p>
            <div class="row justify-content-center">
              <div class="col-10">
                <div class="form-group">
                  <input
                    v-model="dataYears.searchText"
                    class="form-control form-control-lg"
                    type="text"
                    placeholder="Search questions"/>
                </div>
                <div class="form-group text-center">
                  <button type="button" class="btn btn-primary" v-on:click="searchYear">Buscar</button>
                </div>
                <div class="form-group">
                  <form ref="formSearch">
                    <div class="form-group">
                        <select class="form-control" v-model="dataYears.amount">
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="150">150</option>
                            <option value="200">200</option>
                        </select>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="form-group">
                <p>Total de preguntas: {{ this.dataYears.resSearch.length }}</p>              
            </div>
            <div class="form-group">
                <button type="button" 
                  class="btn btn-primary" 
                  data-toggle="modal" 
                  data-target="#createYear"
                  v-on:click="submitCourse()">
                  Crear
                </button>            
            </div>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                    <th scope="col">Id</th>
                    <th scope="col">Name</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(quiz, index) in dataYears.resSearch.data" :key="quiz.id">
                  <th scope="row"> {{ index +1 }}</th>
                  <td>{{ quiz.id }}</td>
                  <td>{{ quiz.name }}</td>
                  <td>{{ quiz.updated_at }}</td>
                    <td>
                    <button type="button" 
                      class="btn btn-primary" 
                      data-toggle="modal" 
                      data-target="#editCourse"
                      v-on:click="editCourse(quiz.id)">
                      Editar
                    </button> 
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        
    </div>
    
    <div v-if="createBlock.status">

        <div v-if="!dataBlocks.viewSearch">
          <p>sin search</p>
          <div class="row justify-content-center">
            <div class="col-10">
              <div class="form-group">
                <input
                  v-model="dataBlocks.searchText"
                  class="form-control form-control-lg"
                  type="text"
                  placeholder="Search questions"/>
              </div>
              <div class="form-group">
                <form ref="formSearch">
                  <div class="form-group">
                      <select class="form-control" v-model="dataBlocks.amount">
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="150">150</option>
                          <option value="200">200</option>
                      </select>
                  </div>
                </form>
              </div>
              <div class="form-group text-center">
                <button type="button" class="btn btn-primary" v-on:click="searchYear">Buscar</button>
              </div>
              
              <div class="form-group">
                <p>Total de preguntas: {{ this.dataBlocks.listData.length }}</p>              
              </div>
              <div class="form-group">
                <button type="button" 
                  class="btn btn-primary" 
                  data-toggle="modal" 
                  data-target="#createBlock">
                  Crear
                </button> 
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <table class="table table-hover" v-if="!dataBlocks.loadingData">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Id</th>
                    <th scope="col">Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(quiz, index) in dataBlocks.listData" :key="quiz.id">
                    <th scope="row"> {{ index+1 }}</th>
                    <td>{{ quiz.id }}</td>
                    <td>{{ quiz.name }}</td>
                    <td>{{ quiz.slug }}</td>
                    <td>{{ quiz.updated_at }}</td>
                    <td>
                      <button type="button" 
                        class="btn btn-primary" 
                        data-toggle="modal" 
                        data-target="#editCourse"
                        v-on:click="editCourse(quiz.id)">
                        Editar
                      </button> 
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        
        <div class="row justify-content-center" v-else>
          <div class="col">
            <p>search</p>
            <div class="row justify-content-center">
              <div class="col-10">
                <div class="form-group">
                  <input
                    v-model="dataBlocks.searchText"
                    class="form-control form-control-lg"
                    type="text"
                    placeholder="Search questions"/>
                </div>
                <div class="form-group text-center">
                  <button type="button" class="btn btn-primary" v-on:click="searchYear">Buscar</button>
                </div>
                <div class="form-group">
                  <form ref="formSearch">
                    <div class="form-group">
                        <select class="form-control" v-model="dataBlocks.amount">
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="150">150</option>
                            <option value="200">200</option>
                        </select>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="form-group">
                <p>Total de preguntas: {{ this.dataBlocks.resSearch.length }}</p>              
            </div>
            <div class="form-group">
                <button type="button" 
                  class="btn btn-primary" 
                  data-toggle="modal" 
                  data-target="#createBlock"
                  v-on:click="submitBlock()">
                  Crear
                </button>            
            </div>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                    <th scope="col">Id</th>
                    <th scope="col">Name</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(quiz, index) in dataBlocks.resSearch" :key="quiz.id">
                  <th scope="row"> {{ index +1 }}</th>
                  <td>{{ quiz.id }}</td>
                  <td>{{ quiz.name }}</td>
                  <td>{{ quiz.slug }}</td>
                  <td>{{ quiz.updated_at }}</td>
                    <td>
                    <button type="button" 
                      class="btn btn-primary" 
                      data-toggle="modal" 
                      data-target="#editCourse"
                      v-on:click="editCourse(quiz.id)">
                      Editar
                    </button> 
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        
    </div>

    <div v-if="importImagesQuestions.status">

        <div v-if="!dataImageQuestions.viewSearch">
          <p>sin search</p>
          <div class="row justify-content-center">
            <div class="col-10">
              <div class="form-group">
                <input
                  v-model="dataImageQuestions.searchText"
                  class="form-control form-control-lg"
                  type="text"
                  placeholder="Search questions"/>
              </div>
              <div class="form-group">
                <form ref="formSearch">
                  <div class="form-group">
                      <select class="form-control" v-model="dataImageQuestions.amount">
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="150">150</option>
                          <option value="200">200</option>
                      </select>
                  </div>
                </form>
              </div>
              <div class="form-group text-center">
                <button type="button" class="btn btn-primary" v-on:click="searchImageQuestion">Buscar</button>
              </div>
              
              <div class="form-group">
                <p>Total de preguntas: {{ this.dataImageQuestions.listData.total }}</p>              
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <table class="table table-hover" v-if="!dataImageQuestions.loadingData">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">id</th>
                    <th scope="col">user</th>
                    <th scope="col">question</th>
                    <th scope="col">name</th>
                    <th scope="col">content</th>
                    <th scope="col">Created</th>
                    <th scope="col">View</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(quiz, index) in dataImageQuestions.listData.data" :key="quiz.id">
                    <th scope="row"> {{ index+1 }}</th>
                    <td>{{ quiz.id}}</td>
                    <td>{{ quiz.user_id}}</td>
                    <td>{{ quiz.question }}</td>
                    <td>{{ quiz.name }}</td>
                    <td>{{ quiz.content }}</td>
                    <td>{{ quiz.created_at }}</td>
                    <td>
                      <button type="button" 
                        class="btn btn-primary" 
                        data-toggle="modal" 
                        data-target="#viewImage"
                        v-on:click="viewImage(quiz.id)">
                        View
                      </button> 
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        
        <div class="row justify-content-center" v-else>
          <div class="col">
            <p>search</p>
            <div class="row justify-content-center">
              <div class="col-10">
                <div class="form-group">
                  <input
                    v-model="dataImageQuestions.searchText"
                    class="form-control form-control-lg"
                    type="text"
                    placeholder="Search questions"/>
                </div>
                <div class="form-group text-center">
                  <button type="button" class="btn btn-primary" v-on:click="searchImageQuestion">Buscar</button>
                </div>
                <div class="form-group">
                  <form ref="formSearch">
                    <div class="form-group">
                        <select class="form-control" v-model="dataImageQuestions.amount">
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="150">150</option>
                            <option value="200">200</option>
                        </select>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="form-group">
                <p>Total de preguntas: {{ this.dataImageQuestions.resSearch.total }}</p>              
            </div>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                    <th scope="col">id</th>
                    <th scope="col">user</th>
                    <th scope="col">question</th>
                    <th scope="col">name</th>
                    <th scope="col">content</th>
                    <th scope="col">Created</th>
                    <th scope="col">View</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(quiz, index) in dataImageQuestions.resSearch.data" :key="quiz.id">
                  <th scope="row"> {{ index+1 }}</th>
                    <td>{{ quiz.id}}</td>
                    <td>{{ quiz.user_id}}</td>
                    <td>{{ quiz.question }}</td>
                    <td>{{ quiz.name }}</td>
                    <td>{{ quiz.content }}</td>
                    <td>{{ quiz.created_at }}</td>
                    <td>
                      <button type="button" 
                        class="btn btn-primary" 
                        data-toggle="modal" 
                        data-target="#viewImage"
                        v-on:click="viewImage(quiz.id)">
                        View
                      </button> 
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        
    </div>


    <!-- Modal QuestionFull -->
    <div class="modal fade" id="questionFull" v-if="quizSelect"
      data-backdrop="static" data-keyboard="false" 
      tabindex="-1" aria-labelledby="staticBackdropLabel" 
      aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputEmail4">Block: {{ quizSelect.block.name }}</label>
                <input type="textt" class="form-control" placeholder="id" id="inputEmail4">
              </div>
              <div class="form-group col-md-6">
                <label for="inputPassword4">Curso: {{ quizSelect.course.name }}</label>
                <input type="textt" class="form-control" placeholder="id" id="inputPassword4">
              </div>
              </div>
              <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputEmail4">Theme: {{ quizSelect.team.name }}</label>
                <input type="text" class="form-control" placeholder="id" id="inputEmail4">
              </div>
              <div class="form-group col-md-6">
                <label for="inputPassword4">Year: {{ quizSelect.year.name }}</label>
                <input type="text" class="form-control" placeholder="id" id="inputPassword4">
              </div>
              </div>
              <div class="form-group">
                <label for="exampleFormControlTextarea1">Body</label>
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="quizSelect.body"></textarea>
              </div>
              
              <div class="form-group">
              <label for="inputAddress">Key: {{ quizSelect.key.keys }}</label>
              <input type="text" class="form-control" id="inputAddress" placeholder="1234 Main St">
              </div>
              <div class="form-group">
              <label for="inputAddress">Answer:</label>
              <input type="text" class="form-control" id="inputAddress" :placeholder="quizSelect.answer.a">
              </div>
              <div class="form-group">
              <label for="inputAddress">Answer:</label>
              <input type="text" class="form-control" id="inputAddress" :placeholder="quizSelect.answer.b">
              </div>
              <div class="form-group">
              <label for="inputAddress">Answer:</label>
              <input type="text" class="form-control" id="inputAddress" :placeholder="quizSelect.answer.c">
              </div>
              <div class="form-group">
              <label for="inputAddress">Answer:</label>
              <input type="text" class="form-control" id="inputAddress" :placeholder="quizSelect.answer.d">
              </div>
              <div class="form-group">
              <label for="inputAddress">Answer:</label>
              <input type="text" class="form-control" id="inputAddress" :placeholder="quizSelect.answer.e">
              </div>
              <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputState">Point</label>
                <select id="inputState" class="form-control">
                <option selected>Choose...</option>
                <option>...</option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label for="inputState">Difficulty</label>
                <select id="inputState" class="form-control">
                <option selected>Choose...</option>
                <option>...</option>
                </select>
              </div>
              
              </div>
              <button type="submit" class="btn btn-primary">Sign in</button>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary">Understood</button>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Modal QuestionBody -->
    <div class="modal fade" id="questionBody" v-if="quizSelect"
      data-backdrop="static" data-keyboard="false" 
      tabindex="-1" aria-labelledby="staticBackdropLabel" 
      aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Question body</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <p>Block: {{ quizSelect.block.name }}</p>
                </div>
                <div class="form-group col-md-6">
                  <p>Course: {{ quizSelect.course.name }}</p>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <p>Theme: {{ quizSelect.team.name }}</p>
                </div>
                <div class="form-group col-md-6">
                  <p>Year: {{ quizSelect.year.name }}</p>
                </div>
              </div>
              <div class="form-group">
                <label for="exampleFormControlTextarea1">Body</label>
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="quizSelect.body"></textarea>
              </div>
              <div class="form-group">
                  <p>Key: {{ quizSelect.key.keys }}</p>
              </div>
              <div class="form-group">
                  <p>Answer: {{ quizSelect.answer.a }}</p>
              </div>
              <div class="form-group">
                  <p>Answer: {{ quizSelect.answer.b }}</p>
              </div>
              <div class="form-group">
                  <p>Answer: {{ quizSelect.answer.c }}</p>
              </div>
              <div class="form-group">
                  <p>Answer: {{ quizSelect.answer.d }}</p>
              </div>
              <div class="form-group">
                  <p>Answer: {{ quizSelect.answer.e }}</p>
              </div>
              <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputState">Point</label>
                <select id="inputState" class="form-control">
                <option selected>Choose...</option>
                <option>...</option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label for="inputState">Difficulty</label>
                <select id="inputState" class="form-control">
                <option selected>Choose...</option>
                <option>...</option>
                </select>
              </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" v-on:click="updateQuesquinBody()">Save</button>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Modal QuestionView -->
    <div class="modal fade" id="questionView" v-if="quizSelect"
      data-backdrop="static" data-keyboard="false" 
      tabindex="-1" aria-labelledby="staticBackdropLabel" 
      aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Question body</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row text-center">
                <div class="col">
                    <!-- Nav -->
                    <div class="row justify-content-center">
                        <div class="dtema col col-sm-12 col-md-8 col-lg-6 col-xl-6">
                            <div class="row justify-content-between">
                                <div class="col text-center align-self-center nav_title_color">
                                    <h5 class="tbar">{{quizSelect.course.name}} <br> 
                                    {{quizSelect.team.name}}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Question -->
                    <div class="row py-4 justify-content-center">
                        <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 text-left">
                            <div v-html="quizSelect.body"></div>
                        </div>
                    </div>
                    <!-- Year -->
                    <div class="row py-4 justify-content-center">
                        <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 text-right">
                            <p class="text-muted">
                                {{ quizSelect.block.name }} - 
                                {{ quizSelect.year.name }}</p>
                        </div>
                    </div>
                    <!-- Answer -->
                    <div class="row justify-content-center">
                        <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
                            <p class="btn_long btn_text_color">
                                {{quizSelect.answer.a}}
                            </p>                        
                            <p class="btn_long btn_text_color">
                                {{quizSelect.answer.b}}
                            </p>                        
                            <p class="btn_long btn_text_color">
                                {{quizSelect.answer.c}}
                            </p>                        
                            <p class="btn_long btn_text_color">
                                {{quizSelect.answer.d}}
                            </p>                        
                            <p class="btn_long btn_text_color">
                                {{quizSelect.answer.e}}
                            </p>                        
                        </div>
                    </div>
                    <!-- Key -->
                    <div class="row py-2 justify-content-center">
                        <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
                            <p class="btn_long_key btn_text_color">
                                {{quizSelect.key.keys}}
                            </p> 
                        </div>
                    </div>
                </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Modal Create Course -->
    <div class="modal fade" id="createCourse"
      data-backdrop="static" data-keyboard="false" 
      tabindex="-1" aria-labelledby="staticBackdropLabel" 
      aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Question body</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="inputAddress">Course</label>
              <input type="text" v-model="createCourse.name" @change="courseSlug" class="form-control" id="inputAddress" placeholder="1234 Main St">
            </div>
            <div class="form-group">
              <label for="inputAddress">Slug</label>
              <input type="text" v-model="createCourse.slug" class="form-control" id="inputAddress" placeholder="1234 Main St">
            </div>
            
            <div class="input-group mb-3">
              <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroupFileCourse">Upload course</span>
              </div>
              <div class="custom-file">
              <input type="file" 
                ref="course" class="custom-file-input"
                v-on:change="handleUpdateCourse()" 
                id="inputGroupFileCourse01" 
                aria-describedby="inputGroupFileCourse">
              <label class="custom-file-label" for="inputGroupFileCourse01">Choose file</label>
              </div>
            </div>
            
            <div class="form-group">
              <label for="exampleFormControlSelect1">Status</label>
              <select class="form-control" id="exampleFormControlSelect1" v-model="createCourse.status_id">
                <option value="1">Free</option>
                <option value="2">Publicado</option>
                <option value="3">Borrador</option>
              </select>
            </div>
            
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" 
              class="btn btn-primary" 
              v-on:click="submitCourse()"
              >Crear</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Edit Course -->
    <div class="modal fade" id="editCourse" v-if="courseSelect"
      data-backdrop="static" data-keyboard="false" 
      tabindex="-1" aria-labelledby="staticBackdropLabel" 
      aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Question body</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="inputAddress">Course</label>
              <input type="text" v-model="courseSelect.name" class="form-control" id="inputAddress" placeholder="1234 Main St">
            </div>
            <div class="form-group">
              <label for="inputAddress">Slug</label>
              <input type="text" v-model="courseSelect.slug" class="form-control" id="inputAddress" placeholder="1234 Main St">
            </div>
            
            <div class="input-group mb-3">
              <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroupFileCourse">Upload course</span>
              </div>
              <div class="custom-file">
              <input type="file" 
                ref="course" class="custom-file-input"
                v-on:change="handleUpdateCourse()" 
                id="inputGroupFileCourse01" 
                aria-describedby="inputGroupFileCourse">
              <label class="custom-file-label" for="inputGroupFileCourse01">Choose file</label>
              </div>
            </div>
            
            <div class="form-group">
              <label for="exampleFormControlSelect1">Status</label>
              <select class="form-control" id="exampleFormControlSelect1" v-model="courseSelect.status_id">
                <option value="1">Free</option>
                <option value="2">Publicado</option>
                <option value="3">Borrador</option>
              </select>
            </div>
            
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" 
              class="btn btn-primary" 
              v-on:click="submitEditCourse()"
              >Save</button>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Modal Edit Answer -->
    <div class="modal fade" id="editAnswer" v-if="answerSelect"
      data-backdrop="static" data-keyboard="false" 
      tabindex="-1" aria-labelledby="staticBackdropLabel" 
      aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Answer edit</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="inputAddress">A</label>
              <input type="text" v-model="answerSelect.a" class="form-control" id="inputAddress" placeholder="1234 Main St">
            </div>
            <div class="form-group">
              <label for="inputAddress">B</label>
              <input type="text" v-model="answerSelect.b" class="form-control" id="inputAddress" placeholder="1234 Main St">
            </div>
            <div class="form-group">
              <label for="inputAddress">C</label>
              <input type="text" v-model="answerSelect.c" class="form-control" id="inputAddress" placeholder="1234 Main St">
            </div>
            <div class="form-group">
              <label for="inputAddress">D</label>
              <input type="text" v-model="answerSelect.d" class="form-control" id="inputAddress" placeholder="1234 Main St">
            </div>
            <div class="form-group">
              <label for="inputAddress">E</label>
              <input type="text" v-model="answerSelect.e" class="form-control" id="inputAddress" placeholder="1234 Main St">
            </div>
            
            
            
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" 
              class="btn btn-primary" 
              v-on:click="submitEditAnswer()"
              >Save</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Create Theme -->
    <div class="modal fade" id="createTheme"
      data-backdrop="static" data-keyboard="false" 
      tabindex="-1" aria-labelledby="staticBackdropLabel" 
      aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Create theme</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="inputAddress">Theme</label>
              <input type="text" v-model="createTheme.name" class="form-control" id="inputAddress" placeholder="1234 Main St">
            </div>
            <div class="form-group">
              <label for="inputAddress">Slug</label>
              <input type="text" v-model="createTheme.slug" class="form-control" id="inputAddress" placeholder="1234 Main St">
            </div>
            <div class="form-group">
              <label for="inputAddress">Course</label>
              <input type="text" v-model="createTheme.course_id" class="form-control" id="inputAddress" placeholder="1234 Main St">
            </div>
            
            <div class="form-group">
              <label for="exampleFormControlSelect1">Status</label>
              <select class="form-control" id="exampleFormControlSelect1" v-model="createTheme.status_id">
                <option value="1">Free</option>
                <option value="2">Publicado</option>
                <option value="3">Borrador</option>
              </select>
            </div>
            
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" 
              class="btn btn-primary" 
              v-on:click="submitTheme()"
              >Crear</button>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Modal Edit Theme -->
    <div class="modal fade" id="editThema" v-if="themaSelect"
      data-backdrop="static" data-keyboard="false" 
      tabindex="-1" aria-labelledby="staticBackdropLabel" 
      aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Edit thema</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="inputAddress">Theme</label>
              <input type="text" v-model="themaSelect.name" class="form-control" id="inputAddress" placeholder="1234 Main St">
            </div>
            <div class="form-group">
              <label for="inputAddress">Slug</label>
              <input type="text" v-model="themaSelect.slug" class="form-control" id="inputAddress" placeholder="1234 Main St">
            </div>
            <div class="form-group">
              <label for="inputAddress">Course</label>
              <input type="text" v-model="themaSelect.course_id" class="form-control" id="inputAddress" placeholder="1234 Main St">
            </div>
            
            <div class="form-group">
              <label for="exampleFormControlSelect1">Status</label>
              <select class="form-control" id="exampleFormControlSelect1" v-model="themaSelect.status_id">
                <option value="1">Free</option>
                <option value="2">Publicado</option>
                <option value="3">Borrador</option>
              </select>
            </div>
            
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" 
              class="btn btn-primary" 
              v-on:click="submitEditThema()"
              >Save</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Create Question -->
    <div class="modal fade" id="createQuestion"
      data-backdrop="static" data-keyboard="false" 
      tabindex="-1" aria-labelledby="staticBackdropLabel" 
      aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Create question</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="inputAddress">Block_id</label>
              <input type="text" v-model="createQuestion.block_id" class="form-control" id="inputAddress" placeholder="1234 Main St">
            </div>
            <div class="form-group">
              <label for="inputAddress">Course_id</label>
              <input type="text" v-model="createQuestion.course_id" class="form-control" id="inputAddress" placeholder="1234 Main St">
            </div>
            <div class="form-group">
              <label for="inputAddress">Theme_id</label>
              <input type="text" v-model="createQuestion.team_id" class="form-control" id="inputAddress" placeholder="1234 Main St">
            </div>
            
            <div class="form-group">
              <label for="exampleFormControlTextarea1">Body</label>
              <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="createQuestion.body"></textarea>
            </div>

            <div class="form-group">
              <label for="inputAddress">Key_id</label>
              <input type="text" v-model="createQuestion.key_id" class="form-control" id="inputAddress" placeholder="1234 Main St">
            </div>

            <div class="form-group">
              <label for="inputAddress">Answers_id</label>
              <input type="text" v-model="createQuestion.answer_id" class="form-control" id="inputAddress" placeholder="1234 Main St">
            </div>
            
            <div class="form-group">
              <label for="inputAddress">Year_id</label>
              <input type="text" v-model="createQuestion.year_id" class="form-control" id="inputAddress" placeholder="1234 Main St">
            </div>
              
            
            <div class="form-group">
              <label for="exampleFormControlSelect1">Point</label>
              <select class="form-control" id="exampleFormControlSelect1" v-model="createQuestion.point_id">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>
            <div class="form-group">
              <label for="exampleFormControlSelect1">Difficulty</label>
              <select class="form-control" id="exampleFormControlSelect1" v-model="createQuestion.difficulty_id">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>
            
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" 
              class="btn btn-primary" 
              v-on:click="submitQuestion()"
              >Crear</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Create year -->
    <div class="modal fade" id="createYear"
      data-backdrop="static" data-keyboard="false" 
      tabindex="-1" aria-labelledby="staticBackdropLabel" 
      aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Year</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="inputAddress">Year</label>
              <input type="text" v-model="createYear.name" class="form-control" id="inputAddress" placeholder="1234 Main St">
            </div>
            
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" 
              class="btn btn-primary" 
              v-on:click="submitYear()"
              >Save</button>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Modal View image -->
    <div class="modal fade" id="viewImage"
      data-backdrop="static" data-keyboard="false" 
      tabindex="-1" aria-labelledby="staticBackdropLabel" 
      aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">View image</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <img :src="this.appDomainImg + 'questionimages/'+ imageSelect.name" style="width:90%" alt="">
            </div>
            
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" 
              class="btn btn-primary" 
              v-on:click="submitYear()"
              >Save</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Create Blocks -->
    <div class="modal fade" id="createBlock"
      data-backdrop="static" data-keyboard="false" 
      tabindex="-1" aria-labelledby="staticBackdropLabel" 
      aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Create block</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="inputAddress">Name</label>
              <input type="text" v-model="createBlock.name" @change="blockSlug" class="form-control" id="blockName" placeholder="1234 Main St">
            </div>
            <div class="form-group">
              <label for="inputAddress">Slug</label>
              <input type="text" v-model="createBlock.slug" class="form-control" id="blockSlug" placeholder="1234 Main St">
            </div>
            
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" 
              class="btn btn-primary" 
              v-on:click="submitBlock()"
              >Crear</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
//import { mapState } from "vuex";
import axios from 'axios'
import UpdateImage from '@/components/admin/import/question/update';



export default {
  name: 'importQuestion',
  components: {
    UpdateImage
  },
  data() {
    return {
      quizSelect:"",
      courseSelect:"",
      answerSelect:"",
      themaSelect:"",
      imageSelect:"",
      dataQuestions:{
        amount:"10",
        search: {
          id:"",
          user:"",
          block:"",
          course:"",
          theme:"",
          body:"",
          difficulty:"",
          point:"",
          order:"",
        },
        loadingData:true,
        listData: [],
        viewSearch:false,
        resSearch: [],
      },
      dataAnswers:{
        amount:"10",
        searchText: "",
        listData: [],
        viewSearch:false,
        resSearch: [],
      },
      dataThemas:{
        amount:"10",
        searchText: "",
        listData: [],
        viewSearch:false,
        resSearch: [],
      },
      dataBlocks:{
        amount:"10",
        searchText: "",
        listData: [],
        viewSearch:false,
        resSearch: [],
      },
      dataCourses:{
        amount:"10",
        searchText: "",
        listData: [],
        viewSearch:false,
        resSearch: [],
      },
      dataKeys:{
        amount:"10",
        searchText: "",
        listData: [],
        viewSearch:false,
        resSearch: [],
      },
      dataYears:{
        amount:"10",
        searchText: "",
        listData: [],
        viewSearch:false,
        resSearch: [],
      },
      dataImageQuestions:{
        amount:"10",
        searchText: "",
        listData: [],
        viewSearch:false,
        resSearch: [],
      },
      importKeys:{
        status:false,
        file:"",
      },
      importAnswers:{
        status:false,
        file:"",
      },
      importQuestions:{
        status:true,
        file:"",
      },
      importImagesQuestions:{
        status:false,
        file:[],
      },
      importThemes:{
        status:false,
        file:"",
      },
      createCourse:{
        status:false,
        name:"",
        slug:"",
        file:"",
        status_id:""
      },
      createTheme:{
        status:false,
        name:"",
        slug:"",
        course_id:"",
        status_id:""
      },
      createQuestion:{
        status:false,
        team_id:"",
        year_id:"",
        course_id:"",
        key_id:"",
        answer_id:"",
        block_id:"",
        body:"",
        point_id:"",
        difficulty_id:"",
      },
      createYear:{
        status:false,
        name:"",
      },
      createBlock:{
        status:false,
        name:"",
        slug:"",
      },
      createImage:{
        status:false,
        question:"",
        name:"",
        content:"",
      },
      thema:{
        editData:{
          name:"",
          slug:"",
          course_id:"",
          content:"",
          status_id:"",
        }
      },
      block:{
        status:false,
        viewSearch:false,
        idSelect:"",
        importData:{},
        getData:"",
        editData:{
          status:false,
          name:"",
          slug:"",
        },
        createData:{
          status:false,
          name:"",
          slug:"",
        },
        searchData:{
          amount:"10",
          searchText: "",
        },
        resData:"",
      },
      //loading:true
    };
  },
  created() {
    this.getQuestions();
  },
  mounted() {
    window.addEventListener('DOMContentLoaded', this.blockSlug);
  },
  beforeDestroy() {
    window.removeEventListener ('DOMContentLoaded', this.blockSlug) 
  },
  methods: {
    blockSlug(){
      console.log("blockSlug");
       var name = this.createBlock.name;
       var slug = this.createBlock.slug;

      console.log(name);
      console.log(slug);

      slug = this.string_to_slug(name);
    },
    string_to_slug(str){
      console.log(str);
      console.log("string_to_slug");

      str = str.replace(/^\s+|\s+$/g, ''); // trim
      str = str.toLowerCase();
  
      // remove accents, swap ñ for n, etc
      var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to   = "aaaaeeeeiiiioooouuuunc------";
      for (var i=0, l=from.length ; i<l ; i++) {
          str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }
  
      str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
          .replace(/\s+/g, '-') // collapse whitespace and replace by -
          .replace(/-+/g, '-'); // collapse dashes
  
      console.log(str);
      this.createBlock.slug = str;

      return str;
    },
    courseSlug(){
      console.log("blockSlug");
       var name = this.createCourse.name;
       var slug = this.createCourse.slug;

      console.log(name);
      console.log(slug);

      slug = this.string_to_slug_course(name);
    },
    string_to_slug_course(str){
      console.log(str);
      console.log("string_to_slug");

      str = str.replace(/^\s+|\s+$/g, ''); // trim
      str = str.toLowerCase();
  
      // remove accents, swap ñ for n, etc
      var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to   = "aaaaeeeeiiiioooouuuunc------";
      for (var i=0, l=from.length ; i<l ; i++) {
          str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }
  
      str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
          .replace(/\s+/g, '-') // collapse whitespace and replace by -
          .replace(/-+/g, '-'); // collapse dashes
  
      console.log(str);
      this.createCourse.slug = str;

      return str;
    },
    updateAnswersSinClave(){
      console.log('updateAnswersSinClave');
      const url = this.appDomainApi1 + "admin/import/question/sin-clave"
      const params = {}
      axios.post(url,params)
      .then(res => {
        console.log(res)
        this.getAnswers();
      })
      .catch(err => {
        console.error(err); 
      })

    },
    updateQuesquinBody(){
      const url = this.appDomainApi1 + "admin/import/question/update-questions-body"
      const params = {
        id: this.quizSelect.id,
        body: this.quizSelect.body
      }
      axios.post(url,params)
      .then(res => {
        console.log(res)
      })
      .catch(err => {
        console.error(err); 
      })
    },
    viewQuestion($idQuiz){
      console.log($idQuiz);
      //const quizId = this.dataQuestions.listData.data.filter(quizId => quizId.id === '4');
      //this.quizSelect = this.dataQuestions.listData.data.find(quizId => quizId.id === $idQuiz);
      const quizId = this.dataQuestions.listData.data.find(quizId => quizId.id === $idQuiz);
      this.quizSelect = quizId;
      //const quizId = myArray.filter(x => x.id === '45');
      console.log(this.dataQuestions.listData.data);
      console.log(this.quizSelect);
    },
    viewQuestionSearch($idQuiz){
      console.log($idQuiz);
      //const quizId = this.dataQuestions.listData.data.filter(quizId => quizId.id === '4');
      //this.quizSelect = this.dataQuestions.listData.data.find(quizId => quizId.id === $idQuiz);
      const quizId = this.dataQuestions.resSearch.data.find(quizId => quizId.id === $idQuiz);
      this.quizSelect = quizId;
      //const quizId = myArray.filter(x => x.id === '45');
      console.log(this.dataQuestions.listData.data);
      console.log(this.quizSelect);
    },
    editCourse($idCourse){
      console.log($idCourse);
      const courseId = this.dataCourses.listData.find(courseId => courseId.id === $idCourse);
      this.courseSelect = courseId;
      console.log(this.courseSelect);
    },
    editAnswer($idAnswer){
      console.log($idAnswer);
      const answerId = this.dataAnswers.resSearch.data.find(answerId => answerId.id === $idAnswer);
      this.answerSelect = answerId;
      console.log(this.answerSelect);
    },
    editThema($idThema){
      console.log($idThema);
      const themaId = this.dataThemas.listData.data.find(themaId => themaId.id === $idThema);
      this.themaSelect = themaId;
      console.log(this.themaSelect);
    },
    editCourseSearch($idCourse){
      console.log($idCourse);
      const courseId = this.dataCourses.resSearch.data.find(courseId => courseId.id === $idCourse);
      this.courseSelect = courseId;
      console.log(this.courseSelect);
    },
    viewImage($idImage){
      console.log("idImage select");
      console.log($idImage);
      const imageId = this.dataImageQuestions.listData.data.find(imageId => imageId.id === $idImage);
      this.imageSelect = imageId;
      console.log(this.imageSelect);
    },
    viewImageSearch($idImage){
      console.log("idImage viewImageSearch");
      console.log($idImage);
      const imageId = this.dataImageQuestions.resSearch.data.find(imageId => imageId.id === $idImage);
      this.imageSelect = imageId;
      console.log(this.imageSelect);
    },
    getQuestions() {
      const url = this.appDomainApi1 + "admin/import/question/data-questions"
      axios
        .get(url,)
        .then((resp) => {
          this.dataQuestions.listData = resp.data;
          console.log(resp)
          this.dataQuestions.loadingData = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAnswers() {
      const url = this.appDomainApi1 + "admin/import/question/data-answers"
      axios
        .get(url,)
        .then((resp) => {
          this.dataAnswers.listData = resp.data;
          console.log(resp)
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getThemas() {
      const url = this.appDomainApi1 + "admin/import/question/data-themas"
      axios
        .get(url,)
        .then((resp) => {
          this.dataThemas.listData = resp.data;
          console.log(resp)
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getKeys() {
      const url = this.appDomainApi1 + "admin/import/question/data-keys"
      axios
        .get(url,)
        .then((resp) => {
          this.dataKeys.listData = resp.data;
          console.log(resp)
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getBlocks() {
      const url = this.appDomainApi1 + "admin/import/question/data-blocks"
      axios
        .get(url,)
        .then((resp) => {
          this.dataBlocks.listData = resp.data;
          console.log(resp)
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCourses() {
      const url = this.appDomainApi1 + "admin/import/question/data-courses"
      axios
        .get(url,)
        .then((resp) => {
          this.dataCourses.listData = resp.data;
          console.log(resp)
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getYears() {
      const url = this.appDomainApi1 + "admin/import/question/data-years"
      axios
        .get(url,)
        .then((resp) => {
          this.dataYears.listData = resp.data;
          console.log(resp)
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getImageQuestions() {
      const url = this.appDomainApi1 + "admin/import/question/data-images"
      axios
        .get(url,)
        .then((resp) => {
          this.dataImageQuestions.listData = resp.data;
          console.log(resp)
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    search() {
      console.log("search quiz");
      this.dataQuestions.viewSearch=true;
      const url = this.appDomainApi1 + "admin/import/question/search";
      axios
        .post(
          url, { 
            block: this.dataQuestions.search.block,
            course: this.dataQuestions.search.course,
            theme: this.dataQuestions.search.theme,
            difficulty: this.dataQuestions.search.difficulty,
            point: this.dataQuestions.search.point,
            body: this.dataQuestions.search.body,
            id: this.dataQuestions.search.id,
            order: this.dataQuestions.search.order,
            register: this.dataQuestions.amount
            },
        )
        .then((resp) => {
          console.log(resp);
          console.log(resp.status);
          if (resp.status == 200) {
            console.log("if");
            this.dataQuestions.resSearch = resp.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchAnswer() {
      console.log("searchAnswer");
      this.dataAnswers.viewSearch=true;
      const url = this.appDomainApi1 + "admin/import/question/search-answer";
      axios
        .post(
          url, { 
            search: this.dataAnswers.searchText ,
            register: this.dataAnswers.amount
            },
        )
        .then((resp) => {
          console.log(resp);
          console.log(resp.status);
          if (resp.status == 200) {
            this.dataAnswers.resSearch = resp.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchYear() {
      console.log("searchYear");
      this.dataYears.viewSearch=true;
      const url = this.appDomainApi1 + "admin/import/question/search-year";
      axios
        .post(
          url, { 
            search: this.dataYears.searchText ,
            register: this.dataYears.amount
            },
        )
        .then((resp) => {
          console.log(resp);
          console.log(resp.status);
          if (resp.status == 200) {
            this.dataYears.resSearch = resp.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchTheme() {
      console.log("searchTheme");
      this.dataThemas.viewSearch=true;
      const url = this.appDomainApi1 + "admin/import/question/search-theme";
      axios
        .post(
          url, { 
            search: this.dataThemas.searchText ,
            register: this.dataThemas.amount
            },
        )
        .then((resp) => {
          console.log(resp);
          console.log(resp.status);
          if (resp.status == 200) {
            this.dataThemas.resSearch = resp.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchKey() {
      console.log("searchYear");
      this.dataKeys.viewSearch=true;
      const url = this.appDomainApi1 + "admin/import/question/search-key";
      axios
        .post(
          url, { 
            search: this.dataKeys.searchText ,
            register: this.dataKeys.amount
            },
        )
        .then((resp) => {
          console.log(resp);
          console.log(resp.status);
          if (resp.status == 200) {
            this.dataKeys.resSearch = resp.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchImageQuestion() {
      console.log("searchImageQuestion");
      this.dataImageQuestions.viewSearch=true;
      const url = this.appDomainApi1 + "admin/import/question/search-images";
      axios
        .post(
          url, { 
            search: this.dataImageQuestions.searchText ,
            register: this.dataImageQuestions.amount
            },
        )
        .then((resp) => {
          console.log(resp);
          console.log(resp.status);
          if (resp.status == 200) {
            this.dataImageQuestions.resSearch = resp.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    btnKeys(){
      console.log("btnKeys")
      this.importKeys.status = true;
      this.importAnswers.status = false;
      this.importQuestions.status = false;
      this.importThemes.status = false;
      this.importImagesQuestions.status = false;
      this.createCourse.status = false;
      this.createYear.status = false;
      this.createBlock.status = false;
      this.getKeys();
    },
    btnAnswers(){
      this.importKeys.status = false;
      this.importAnswers.status = true;
      this.importQuestions.status = false;
      this.importThemes.status = false;
      this.importImagesQuestions.status = false;
      this.createCourse.status = false;
      this.createYear.status = false;
      this.createBlock.status = false;
      this.getAnswers();
    },
    btnQuestions(){
      this.importKeys.status = false;
      this.importAnswers.status = false;
      this.importQuestions.status = true;
      this.importThemes.status = false;
      this.importImagesQuestions.status = false;
      this.createCourse.status = false;
      this.createYear.status = false;
      this.createBlock.status = false;
    },
    btnThemes(){
      this.importKeys.status = false;
      this.importAnswers.status = false;
      this.importQuestions.status = false;
      this.importThemes.status = true;
      this.importImagesQuestions.status = false;
      this.createCourse.status = false;
      this.createYear.status = false;
      this.createBlock.status = false;
      this.getThemas();
    },
    btnImagesQuestions(){
      this.importKeys.status = false;
      this.importAnswers.status = false;
      this.importQuestions.status = false;
      this.importThemes.status = false;
      this.importImagesQuestions.status = true;
      this.createCourse.status = false;
      this.createYear.status = false;
      this.createBlock.status = false;
      this.getImageQuestions();
    },
    btnCourses(){
      this.importKeys.status = false;
      this.importAnswers.status = false;
      this.importQuestions.status = false;
      this.importThemes.status = false;
      this.importImagesQuestions.status = false;
      this.createCourse.status = true;
      this.createYear.status = false;
      this.createBlock.status = false;
      this.getCourses();
    },
    btnBlocks(){
      this.importKeys.status = false;
      this.importAnswers.status = false;
      this.importQuestions.status = false;
      this.importThemes.status = false;
      this.importImagesQuestions.status = false;
      this.createCourse.status = false;
      this.createYear.status = false;
      this.createBlock.status = true;
      this.getBlocks();
    },
    btnYears(){
      this.importKeys.status = false;
      this.importAnswers.status = false;
      this.importQuestions.status = false;
      this.importThemes.status = false;
      this.importImagesQuestions.status = false;
      this.createCourse.status = false;
      this.createYear.status = true;
      this.createBlock.status = false;
      this.getYears();
    },
    handleUpdateKeys(){
      console.log("handleUpdate");
      this.importKeys.file = this.$refs.keys.files[0];
    },
    submitFileKeys(){
      let formData = new FormData();
      formData.append('file', this.importKeys.file);
      const url = this.appDomainApi1 + "admin/import/question/import-keys";
      const letf = this;
      axios.post( url, formData, { headers: { 'Content-Type': 'multipart/form-data'} }
      ).then(function(response){
        console.log('SUCCESS!!');
        console.log(response);
        letf.getKeys();
      })
      .catch(function(errors){
        console.log(errors.response);
        console.log('FAILURE!!');
      });
    },
    handleUpdateAnswers(){
      console.log("handleUpdateAnswers");
      this.importAnswers.file = this.$refs.answers.files[0];
    },
    submitFileAnswers(){
      console.log("submitFileAnswers");
      let formData = new FormData();
      formData.append('file', this.importAnswers.file);
      const url = this.appDomainApi1 + "admin/import/question/import-answers";
      const letf = this;
      axios.post( url, formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      }
      ).then(function(response){
        console.log('SUCCESS!!');
        letf.getAnswers();
        console.log(response);
      })
      .catch(function(errors){
        console.log(errors.response);
        console.log('FAILURE!!');
      });
    },
    handleUpdateQuestions(){
      console.log("submitFileQuestions");
      this.importQuestions.file = this.$refs.questions.files[0];
    },
    submitFileQuestions(){
      console.log("submitFileQuestions");
      let formData = new FormData();
      formData.append('file', this.importQuestions.file);
      const url = this.appDomainApi1 + "admin/import/question/import-questions";
      const letf = this;
      axios.post( url, formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      }
      ).then(function(response){
        console.log('SUCCESS!!');
        letf.getQuestions();
        console.log(response);
      })
      .catch(function(errors){
        console.log(errors.response);
        console.log('FAILURE!!');
      });
    },
    handleUpdateThemes(){
      console.log("handleUpdateThemes");
      this.importThemes.file = this.$refs.themes.files[0];
    },
    submitFileThemes(){
      console.log("submitFileThemes");
      let formData = new FormData();
      formData.append('file', this.importThemes.file);
      const url = this.appDomainApi1 + "admin/import/question/import-themes";
      const letf = this;
      axios.post( url, formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      }
      ).then(function(response){
        console.log('SUCCESS!!');
        letf.getThemas();
        console.log(response);
      })
      .catch(function(errors){
        console.log(errors);
        console.log('FAILURE!!');
      });
    },
    handleUpdateCourse(){
      console.log("handleUpdateCourse");
      this.createCourse.file = this.$refs.course.files[0];
    },
    submitCourse(){
      console.log("submitCourse");
      let formData = new FormData();
      formData.append('file', this.createCourse.file);
      const url = this.appDomainApi1 + "admin/import/question/create-course";
      const params = {
        name: this.createCourse.name,
        slug: this.createCourse.slug,
        avatar: this.createCourse.avatar,
        status_id: this.createCourse.status_id,
      };
      const letf = this;
      axios.post( url, params, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      }
      ).then(function(response){
        console.log('SUCCESS!!');
        letf.getCourses();
        console.log(response);
      })
      .catch(function(errors){
        console.log(errors);
        console.log('FAILURE!!');
      });
    },
    submitBlock(){
      console.log("submitBlock");
      const url = this.appDomainApi1 + "admin/import/question/create-block";
      const params = {
        name: this.createBlock.name,
        slug: this.createBlock.slug,
      };
      const letf = this;
      axios.post( url, params,)
        .then(function(response){
          console.log('SUCCESS!!');
          letf.getBlocks();
          console.log(response);
      })
      .catch(function(errors){
        console.log(errors);
        console.log('FAILURE!!');
      });
    },
    submitYear(){
      console.log("submitYear");
      const url = this.appDomainApi1 + "admin/import/question/create-year";
      const params = {
        name: this.createYear.name,
      };
      const letf = this;
      axios.post( url, params,)
        .then(function(response){
          console.log('SUCCESS!!');
          letf.getYears();
          console.log(response);
      })
      .catch(function(errors){
        console.log(errors);
        console.log('FAILURE!!');
      });
    },
    submitTheme(){
      console.log("submitTheme");
      const url = this.appDomainApi1 + "admin/import/question/create-theme";
      const params = {
        name: this.createTheme.name,
        slug: this.createTheme.slug,
        course_id: this.createTheme.course_id,
        status_id: this.createTheme.status_id,
      };
      const letf = this;
      axios.post( url, params )
        .then(function(response){
          console.log('SUCCESS!!');
          letf.getThemas();
          console.log(response);
      })
      .catch(function(errors){
        console.log(errors);
        console.log('FAILURE!!');
      });
    },
    submitEditThema(){
      console.log("submitEditCourse");
      const url = this.appDomainApi1 + "admin/import/question/update-thema";
      const params = {
        id: this.themaSelect.id,
        name: this.themaSelect.name,
        slug: this.themaSelect.slug,
        course: this.themaSelect.course.id,
        content: this.themaSelect.content,
        status: this.themaSelect.status_id,
      };
      const letf = this;
      axios.post( url, params).then(function(response){
        console.log('SUCCESS!!');
        letf.getThemas();
        console.log(response);
      })
      .catch(function(errors){
        console.log(errors);
        console.log('FAILURE!!');
      });
    },
    submitEditCourse(){
      console.log("submitEditCourse");
      //let formData = new FormData();
      //formData.append('file', this.createCourse.file);
      const url = this.appDomainApi1 + "admin/import/question/update-course";
      const params = {
        id: this.courseSelect.id,
        name: this.courseSelect.name,
        slug: this.courseSelect.slug,
        //avatar: this.courseSelect.avatar,
        status_id: this.courseSelect.status_id,
      };
      const letf = this;
      axios.post( url, params, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      }
      ).then(function(response){
        console.log('SUCCESS!!');
        letf.getCourses();
        console.log(response);
      })
      .catch(function(errors){
        console.log(errors);
        console.log('FAILURE!!');
      });
    },
    submitEditAnswer(){
      console.log("submitEditAnswer");
      const url = this.appDomainApi1 + "admin/import/question/update-answer";
      const params = {
        id: this.answerSelect.id,
        a: this.answerSelect.a,
        b: this.answerSelect.b,
        c: this.answerSelect.c,
        d: this.answerSelect.d,
        e: this.answerSelect.e,
      };
      const letf = this;
      axios.post( url, params,)
        .then(function(response){
          console.log('SUCCESS!!');
          letf.getCourses();
          console.log(response);
      })
      .catch(function(errors){
        console.log(errors);
        console.log('FAILURE!!');
      });
    },
    submitQuestion(){
      console.log("submitTheme");
      const url = this.appDomainApi1 + "admin/import/question/create-question";
      const params = {
        team_id:  this.createQuestion.team_id,
        year_id:  this.createQuestion.year_id,
        course_id:  this.createQuestion.course_id,
        key_id:   this.createQuestion.key_id,
        answer_id:  this.createQuestion.answer_id,
        block_id:   this.createQuestion.block_id,
        body:   this.createQuestion.body,
        point_id:   this.createQuestion.point_id,
        difficulty_id:  this.createQuestion.difficulty_id,
      };
      const letf = this;
      axios.post( url, params )
        .then(function(response){
          console.log('SUCCESS!!');
          letf.getQuestions();
          console.log(response);
      })
      .catch(function(errors){
        console.log(errors);
        console.log('FAILURE!!');
      });
    },
    handleUpdateImages(){
      console.log("handleUpdateImages");
      //this.dataImageQuestions.file = event.target.files;
        
      //this.importImagesQuestions.file = this.$refs.images.files[0];

      if (this.$refs.images.files.length > 1) {
        console.log('>1');
        console.log(this.$refs.images.files.length);
        console.log(this.$refs.images.files);

        for ( var index=0; index<this.$refs.images.files.length; index++ ) {
          this.importImagesQuestions.file.push( this.$refs.images.files[index] );
        }


      } else {
        console.log('else')        
        console.log(this.$refs.images.files);
      }
      //this.importImagesQuestions.file = this.$refs.images.files[0];
    },
    submitFileImages(){
      console.log("submitFileImages");

      let formData = new FormData();
      formData.append('images', this.importImagesQuestions.file);

      /*
      for (const i of Object.keys(this.importImagesQuestions.file)) {
        //formData.append('files', this.files[i])
        formData.append('images', this.importImagesQuestions.file[i]);
      }
      */
          
      const url = this.appDomainApi1 + "admin/import/question/import-images";
      const letf = this;
      axios.post( url, formData, { headers: { 'Content-Type': 'multipart/form-data'} }
      ).then(function(response){
        console.log('SUCCESS!!');
        console.log(response);
        letf.getImageQuestions();
      })
      .catch(function(errors){
        console.log(errors.response);
        console.log('FAILURE!!');
      });
    },
  },
};
</script>

<style>
.imgav {
  display: flex;
  align-content: center;
  justify-content: center;
}
.cAvatar {
  position: relative;
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 50%;
  overflow: hidden;
}
.cImage {
  position: absolute;
  width: 30px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.loading {
  height: 6.8em;
}
.dc1:hover {
  color: white;
  background: #628cfb;
  transition-duration: 100ms;
  cursor: pointer;
}
.dc1 {
  border-radius: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #e8f0ff;
  margin: 5px;
}
.dDetalle:hover {
  color: white;
}
.dDetalle {
  padding-top: 10px;
}
.dDetalle > p {
  margin-top: 97px;
  /* margin: -15px; */
  margin-left: 6px;
  padding: 0px;
  /* border: 0px; */
  margin-top: -15px;
  font-size: 16px;
  color: #1d2b8d;
  line-height: 1.2;
}
.nutemas {
  color: black;
  margin-left: 16px;
  font-family: inherit;
  font-size: 50px;
}
</style>
