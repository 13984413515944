import axios from "axios";

class UploadFilesService {
  upload(file, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);

    return axios.post(process.env.VUE_APP_DOMANAPI1 + "admin/import/question/import-images", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress
    });
  }

  getFiles() {
    return axios.get(process.env.VUE_APP_DOMANAPI1 + "admin/import/question/data-images");
  }
}

export default new UploadFilesService();
