<template>
  <div>
    <TitleAdmin msg="Questions" routerAdmin="Admin"/>
      <ListQuestion />
  </div>
</template>
<script>
import ListQuestion from '@/components/admin/import/question/index.vue'
import TitleAdmin from '@/views/constants/titleAdmin.vue'

export default {
  name: 'CourseIndex',
  components: {
    ListQuestion,
    TitleAdmin
  },
}
</script>

<style scope>

</style>
